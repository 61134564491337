@use '../../colors' as c;

.footer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	gap: 1rem;
	box-shadow: 0 -3px 4px 0 rgba(74, 74, 74, 0.5);

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2rem;
		justify-content: space-between;
		font-size: 0.7rem;
		padding: 3rem 1rem 2rem;

		&:nth-child(1) {
			padding: 3rem 2rem 2rem;
		}

		&:nth-child(2) {
			border-top: 1px solid c.$grey700;
			padding-top: 1rem;
			flex-direction: row;
		}
	}

	&__links {
		display: flex;
		flex-direction: column;
		text-align: center;
		gap: 1rem;

		article {
			h3 {
				color: c.$grey700;
				margin-bottom: 1rem;
			}
			ul {
				list-style: none;
				li {
					margin-top: 0.4rem;
					button {
						border: none;
						background: none;
						text-decoration: none;
						color: c.$grey500;
						transition: color 0.5s;
						cursor: pointer;
						&:hover {
							color: c.$yellow;
						}
					}
				}
			}
		}
	}

	&__data {
		flex-direction: column;
		text-align: center;
		width: 16rem;
	}

	img {
		height: 7.5rem;
	}
}

@media (min-width: 568px) {
	.footer {
		&__links {
			flex-direction: row;
			gap: 5rem;
		}
	}
}

@media (min-width: 992px) {
	.footer {
		&__content {
			flex-direction: row;
		}

		&__links {
			gap: 4rem;
			text-align: left;

			li {
				margin-top: 0.2rem;
			}
		}

		&__data {
			text-align: left;
		}
	}
}
