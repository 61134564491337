@import '../../colors';

.header {
	box-shadow: 0 3px 4px 0 rgba(74, 74, 74, 0.5);
	&__container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		padding: 4rem 1rem;
		gap: 3rem;
	}
	&__content {
		h1 {
			color: $grey700;
			font-size: 2.52rem;
		}

		&-text {
			margin-bottom: 1rem;
			p {
				margin-top: 0.5rem;
			}
		}

		&-button {
			display: inline-grid;
			grid-template-columns: auto auto;
			align-items: center;
			gap: 0.3rem;
			padding: 0.2rem 0.5rem 0.2rem 0.2rem;
			background-color: $grey500;
			border-radius: 0.5rem;
			color: $white;
			font-size: 0.8rem;

			.icon {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 2.25rem;
				height: 1.75rem;
				background-color: $yellow;
				border-radius: 0.5rem;
				i {
					font-size: 1.5rem;
					color: $grey500;
					transition: transform 0.4s;
				}
			}

			&:hover {
				.icon {
					i {
						transform: rotate(90deg);
					}
				}
			}
		}
	}

	img {
		width: 25rem;
		max-height: 18rem;
	}
}

@media (min-width: 768px) {
	.header {
		&__container {
			flex-direction: row;
		}

		img {
			position: absolute;
			left: 55%;
			max-height: 100%;
		}
	}
}
