@import './colors';

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html {
	font-size: 62.5%;
	scroll-behavior: smooth;
}

body {
	font-family: 'Montserrat', sans-serif;
	color: $grey500;
	background-color: $white;
}

a {
	text-decoration: none;
}

.container {
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
}

main {
	padding: 2rem 1rem;
}

section {
	padding: 2rem 0;
	.section__text {
		padding-bottom: 4rem;
		color: $grey500;
	}
}

.imageHolder {
	width: 25rem;
	max-height: 18rem;
}

.imageHolder {
	background-color: #e6e6e6;
	height: 50%;
	border-radius: 5%;
	animation: blink 2s forwards infinite;
}

@media (min-width: 568px) {
	html {
		font-size: 80%;
	}
}

@media (min-width: 768px) {
	html {
		font-size: 85%;
	}
	.imageHolder {
		position: absolute;
		left: 55%;
		max-height: 100%;
	}
}

@media (min-width: 992px) {
	html {
		font-size: 104%;
	}
}

@media (min-width: 1200px) {
	html {
		font-size: 125%;
	}
}

@keyframes blink {
	25% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	75% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
