@import '../../colors';

.nav {
	position: sticky;
	top: 0;
	box-shadow: 0 3px 4px 0 rgba(74, 74, 74, 0.5);
	background-color: $white;
	z-index: 999;
	&__container {
		padding: 1rem 2rem;
	}
	&__content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		img {
			height: 3rem;
		}
	}
	&__links {
		display: flex;
		align-items: center;
		gap: 1rem;
		a {
			text-decoration: none;
			color: $grey700;
			font-size: 1.2rem;
			font-weight: 500;
			transition: color 0.5s;
			&:hover {
				color: $yellow;
			}
		}

		.active-link {
			color: $yellow;
		}
	}

	&-btn {
		padding: 0.5rem;
		cursor: pointer;
		background: transparent;
		border: none;
		outline: none;
		color: $grey700;
		display: none;
		opacity: 0;
		font-size: 1.7rem;
	}
}

@media only screen and (max-width: 768px) {
	.nav {
		&__container {
			display: flex;
			justify-content: space-between;
		}

		&-btn {
			display: block;
			opacity: 1;
		}

		&-close-btn {
			position: absolute;
			top: 1rem;
			right: 1rem;
		}

		&__links {
			position: fixed;
			top: 0;
			left: 0;
			justify-content: center;
			flex-direction: column;
			gap: 2rem;
			height: 100%;
			width: 100%;
			background-color: $blue;
			transition: transform 1s;
			transform: translateY(-100vh);

			a {
				font-size: 2rem;
				&:hover {
					color: white;
				}
			}
		}

		.responsive_nav {
			transform: none;
			z-index: 100;
		}
	}
}
