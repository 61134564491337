@import '../../colors';

.contact {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.section__text {
		text-align: center;
		p {
			font-size: 0.9rem;
		}
	}

	&__post {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		width: 50%;
		.form__row {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			.input__box {
				display: flex;
				flex-direction: column;
				gap: 0.3rem;
				width: 100%;

				label {
					font-size: 1rem;
					font-weight: 500;
				}

				input,
				select {
					height: 2.5rem;
				}

				input,
				select,
				textarea {
					border: 1px solid black;
					border-radius: 0.5rem;
					padding: 0 0.5rem;
				}

				textarea {
					padding-top: 0.5rem;
					min-width: 100%;
					max-width: 100%;
					min-height: 7rem;
					max-height: 7rem;
				}

				select {
					cursor: pointer;
				}
			}

			button {
				padding: 0.4rem 2.5rem;
				border: none;
				background-color: $yellow;
				color: $grey500;
				font-size: 1.2rem;
				font-weight: 500;
				border-radius: 0.5rem;
				box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
				cursor: pointer;
			}
		}
        .form__button-reverse {
            justify-content: end;
        }
	}
}

@media (min-width: 768px) {
	.contact {
		.section__text {
			p {
				font-size: 0.7rem;
			}
		}

		&__post {
			.form__row {
				flex-direction: row;
				.input__box {
					label {
						font-size: 0.8rem;
					}

					input,
					select,
					textarea {
						font-size: 0.8rem;
					}
				}
			}
		}
	}
}
