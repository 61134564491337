@import '../../colors';

.visualize {
	&__gallery {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 4rem;
	}
	&__tile {
		width: 100%;

		h3 {
			color: $grey700;
		}

		.images__container {
			display: flex;
			flex-wrap: wrap;
			gap: 1rem;
			justify-content: center;
			margin-top: 1rem;
		}

		&--image {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 14rem;
			width: 17rem;
			border-radius: 0.8rem;
			border: 1px solid black;
			cursor: pointer;
			overflow: hidden;

			img {
				width: 97%;
				height: 97%;
				border-radius: 0.7rem;
				transition: 0.5s transform;
			}

			&:hover {
				img {
					transform: scale(1.4);
				}
			}
		}
	}
}
@media (min-width: 568px) {
	.visualize {
		&__gallery {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 4rem;
		}
		&__tile {
			width: 42rem;
		}
	}
}

@media (min-width: 768px) {
	.visualize {
		&__tile {
			&--image {
				height: 11rem;
				width: 13rem;
			}

			.images__container {
				justify-content: start;
			}
		}
	}
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.enlarged-image {
	max-width: 80%;
	max-height: 80%;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
	border-radius: 8px;
}

.overlay:active {
	cursor: pointer;
}

.fade-in {
	animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: scale(0.9);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
}
