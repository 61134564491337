@import '../../colors';

.main {
	&__photos {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 5rem;
		img {
			width: 25rem;
			height: 18rem;
			border-radius: 1rem;
		}
	}

	&__list {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		align-items: center;
		gap: 6rem;
		ul {
			width: 65%;
			li {
				position: relative;
				padding: 1rem;
				list-style-type: none;
				box-shadow: 0 0 6px 0 $grey500;
				border-radius: 0.4rem;
				font-weight: 500;
				.prefix {
					position: absolute;
					right: 0;
					top: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					height: 100%;
					width: 9%;
					border-radius: 0 0.5rem 0.5rem 200%;
					background-color: $grey500;
					z-index: 1;
					visibility: hidden;
					span {
						color: $yellow;
						font-weight: 900;
						margin-left: 20%;
						margin-bottom: 10%;
					}
				}
			}

			li,
			p {
				margin-top: 1rem;
			}

			p {
				font-size: 0.8rem;
			}
		}
	}
}

@media (min-width: 400px) {
	.main {
		&__list {
			ul {
				li {
					.prefix {
						visibility: visible;
					}
				}
			}
		}
	}
}

@media (min-width: 768px) {
	.main {
		&__photos {
			flex-direction: row;
		}

		&__list {
			flex-direction: row;
			align-items: start;
			justify-content: center;
			ul {
				width: 40%;
				li {
					font-size: 0.8rem;
					.prefix {
						width: 11.5%;
					}
				}
			}
		}
	}
}
